@import '../node_modules/antd/dist/antd.less';

body {
    background-color: #222222;
}

.linkText {
    cursor: pointer;
    color: rgba(0, 0, 0, .65);
}
.linkText:hover {
    color: #000;
    text-decoration: underline;
}

.ant-form-item-label>label.ant-form-item-required:before {
    content: unset;
}

.table {
    margin-top: 2em;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 15px;
    min-height: 60em;
    background-color: gray;
}

.ql-container.ql-snow {
    border: none !important;
    max-height: 34em;
}

// ---- CALENDER 

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notes-month {
    font-size: 28px;
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}

.ant-picker-calendar-mode-switch {
    display: none;
}

// ---- OrgChart 

.reactOrgChart {
    margin-top: 2px;
    margin: auto;
    margin-right: 4em;
    margin-left: 4em;
  }
  
  .orgNodeChildGroup .node {
    border: solid 3px lightgray;
    display: inline-block;
    padding: 4px;
    width: 100px;
  }
  
  .reactOrgChart .orgNodeChildGroup .nodeCell {
    text-align: center;
  }
  
  .reactOrgChart .orgNodeChildGroup .nodeGroupCell {
    vertical-align: top;
  }
  
  .reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    height: 25px;
    //width: 50%;
    border-right: 3px solid lightgray;
  }
  
  .reactOrgChart .nodeLineBorderTop {
    border-top: solid 3px lightgray;
  }
  
  .reactOrgChart table {
    border-collapse: collapse;
    border: none;
    margin: 0 auto;
  }
  
  .reactOrgChart td {
    padding: 0;
  }
  
  .reactOrgChart table.nodeLineTable {
    width: 100%;
  }
  
  .reactOrgChart table td.nodeCell {
    width: 50%;
  }
  
  .boardSelectImg {
    width: 100%;
    height: auto;
  }

// ---- OrgBoard

.deptHead {
    font-weight: 900;
    font-size: 125%;
}

.completedRow {
    background-color: #ECECEC;
}

.onBackRow {
    background-color: rgb(240, 135, 135);
}

@primary-color: #D4AF37;